/* Variables */
.contact-root{
    --inputWidth:15vw;
    --leftWidth:calc(var(--inputWidth)*2);
}
.block-space{
    padding-block: 1.5rem;
}
/* Fonts */
.cursive-mono,.mono{
    font-family: 'Courier New', Courier, monospace;
}
.cursive-mono h2,.cursive{
    font-family: Brush Script MT, Brush Script Std, cursive;
    font-size: 3rem;
}
/* HTML elements */
a{
    text-decoration: none;
}
button{
    border: 1px solid grey;
}
/* containers */
.containers{
    display: flex;
}
.right-container{
    width: 30%;
    background-color: var(--darkBlue);
    color: var(--lightBrown);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.left-container{
    width: 70%;
    color: var(--darkBlue);
    background-color: var(--lightBrown);
}
.right-content{
    width: 75%;
    font-size: 1.1vw;
}

.left-content{
    margin-left: 10%;
}
/* Form */

#form input,#form select{
    width:var(--inputWidth);
}
#form,.textbox,#masjid-map{
    width:var(--leftWidth);
}
select,
select option {
    color: black;
}
select:invalid,
select option[value=""] {
    color: grey;
}

#msg{
    margin-block: 1rem;
}

/* contact us */
.contact-us a{
    color: var(--lightBrown);
}
.contact-us a:hover{
    color: white;
}
.contact-us h5{
    font-weight: 900;
}
.references{
    margin-top: 0.5em;
    font-size: 1.2em;
}
/* Map */
#masjid-map{
    height: max(40vh,20rem);
}

.contact-title{
    font-size: 3.5vw;
}



.contact-subtitle,.right-content h2{
    font-size: 3.25vw;
}


@media only screen and (min-width:1920px) {
    .contact-subtitle,.right-content h2{
        font-size: 4rem;
    }
    .contact-title{
        font-size: 4.5rem;
    }
    .right-content{
        font-size: 1.3rem;
    }
    .contact-root{
        --inputWidth:17.5rem;
    }
    
}
@media only screen and (max-width:910px) {
    .containers{
        flex-direction: column-reverse;
    }
    .left-container,.right-container{
        width: 100%;
    }
    .right-content{
        font-size: 3.75vw;
    }
    .contact-title{
        font-size:10vw;
        margin: 0;
        /* padding: ; */
    }
    .contact-root{
        --inputWidth:40vw;
    }
    .contact-subtitle,.right-content h2{
        font-size: 10vw;
    }
}