.times {
    display: block;
}
.timesWrapper {
    z-index: 10;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    background-color: var(--darkBlue);
    color: var(--lightBrown);
    text-align: center;
    padding-block: 1em;
    font-family: monospace;
}
.circleWrapper {
    display: flex;
    position: absolute;
    height: 39.1vw;
}
.carouselImg {
    float: right;
    width: 69vw;
}

.semiCircle {
    width: 42vw;

    background-color: #000;
    border-radius: 0 60% 60% 0 / 0 100% 100% 0;
    border: 10px solid #000;
    color: seashell;
    /* text-shadow: 10px 10px rgba(0, 128, 0, 0.419); */
    border-left: 0;
    display: flex;
    /* align-items: center; */
}
.semiCircleLine1 {
    border-radius: 0 70% 70% 0 / 0 100% 100% 0;
    border: 30px solid blue;
    opacity: 75%;
}
.semiCircleLine2 {
    border-radius: 0 80% 80% 0 / 0 100% 100% 0;
    border: 20px solid green;
    opacity: 75%;
}
.semiCircleLine1,
.semiCircleLine2 {
    width: 33vw;
    margin-left: -30vw;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
}
.cText {
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-inline: auto;
}

.cText div:last-child {
    text-align: center;
}
.cText h3,
.cText h6 {
    line-height: 0;
}
.cText h1 {
    font-size: 3.5vw;
    font-weight: bold;
}
.cText h3 {
    font-size: 1.4vw;
}
.cText h4 {
    font-size: 1.8vw;
}
.cText h6 {
    font-size: 1vw;
}
@media only screen and (max-width: 1350px) {
    .semiCircleLine1 {
        border: 19.5px solid blue;
    }
    .semiCircleLine2 {
        border: 13px solid green;
    }
    .semiCircleLine1,
    .semiCircleLine2 {
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
    }
}
@media only screen and (max-width: 910px) {
    .cText {
        justify-content: space-around;
    }
    .cText h1 {
        font-size: 7.5vw;
    }
    .cText h3 {
        font-size: 3vw;
    }
    .cText h4 {
        font-size: 3.8vw;
    }
    .cText h6 {
        font-size: 2.14vw;
    }
    .carouselImg {
        width: 100%;
        padding-bottom: 28.65vw;
    }
    .carouselItems {
        display: flex;
        flex-direction: column-reverse;
    }
    .circleWrapper {
        flex-direction: column-reverse;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .semiCircle {
        background-color: #000;
        border-radius: 30% 30% 0 0 / 30% 30% 0 0;
        border: 7.5px solid rgba(0, 128, 0, 0.75);
        box-shadow: inset 0 10px 0 0 rgba(0, 0, 255, 0.75);
        width: 100vw;
        align-self: center;
        text-align: center;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        height: 1000rem;
    }
    .semiCircleLine1,
    .semiCircleLine2 {
        display: none;
    }
}
@media only screen and (max-width: 750px) {
    .timesWrapper h4 {
        font-size: 3.5vw;
    }
    .times {
        font-size: 3vw;
    }
}
@media only screen and (min-width: 1920px) {
    .carouselImg {
        width: 70%;
    }
    .circleWrapper {
        height: 760.75px;
    }
    .semiCircle {
        width: 817.17px;
    }
    .semiCircleLine1,
    .semiCircleLine2 {
        width: 642.5px;
        margin-left: -584.1px;
    }
    .cText h1 {
        font-size: 67.2px;
    }
    .cText h3 {
        font-size: 26.88px;
    }
    .cText h4 {
        font-size: 34.56px;
    }
    .cText h6 {
        font-size: 19.2px;
    }
}
