.backArrow {
    fill: var(--darkBlue);
    height: 20px;
}
.backBtn p {
    color: var(--darkBlue);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
.loading {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.hidePayForm {
    visibility: hidden;
    position: absolute;
}
.thanks {
    text-align: center;
    margin-bottom: 2rem;
}
.sumup {
    color: var(--lightBrown);
    text-decoration: underline;
}
.sumup:hover {
    color: white;
}
#cardNum {
    width: 90%;
    border: 1px solid var(--darkBlue);
    margin: auto;
}
#nameDonateWrapper {
    width: 90%;
    border: 1px solid var(--darkBlue);
    margin: auto;
    margin-top: 1rem;
    padding-block: 0.2rem;
    padding-left: 1rem;
}

#nameDonate {
    width: 100%;
    border: none;
    background: transparent;
    background-color: transparent;
    font-family: 'Podkova', 'Courier New', serif;
}
#payButton {
    width: 90%;
    margin: auto;
}
#cardNum,
.cvcExp {
    margin-top: 1rem;
}
.payElement {
    /* width: 50%; */
    padding-block: 0.5rem;
    padding-left: 1rem;
}
.cvcExp {
    display: flex;
    justify-content: space-evenly;
}
.errorMsg {
    border: 2px solid red;
    background-color: rgb(255, 99, 99);
    text-align: center;
    margin-top: 2rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    word-wrap: break-word;
}

.buttonsAmount {
    display: flex;
    justify-content: center;
}
.buttonsMethod {
    display: flex;
    justify-content: space-evenly;
}
.button {
    margin-block: 2rem;
    border-color: var(--darkBlue);
    color: var(--lightBrown);
    background-color: var(--darkBlue);
}
.backBtn {
    /* border-color: var(--darkBlue); */
    /* margin: 0.5rem; */

    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
}
.button:hover {
    color: var(--lightBrown);
}
.button:focus,
.backBtn:focus,
input:focus {
    outline: none;
    box-shadow: none;
}
.cardelement {
    color: red;
    margin: 10%;
    padding: 1%;
    border: 1px solid black;
    /* border-color: black; */
}

#donate {
    /* margin-left: 5vw; */
    /* margin-top: 2vw; */
    display: flex;
    flex-direction: column;
}
.donate-subtitle {
    text-align: center;
}
.payment-method-details label {
    display: flex;
    flex-direction: column;
}
.right-wrapper {
    width: 30%;
    background-color: var(--darkBlue);
}
#exp,
#cvc {
    width: 42.5%;
    border: 1px solid var(--darkBlue);
}

.left-wrapper {
    width: 70%;
    background-color: var(--lightBrown);
    color: var(--darkBlue);
    margin-bottom: 0;
}
.right-wrapper {
}

.donation-with-other {
    display: flex;
}
.other-donate {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
}
.other-masjid,
.other-bank {
    color: var(--lightBrown);
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem 0;
    border: 1.5px solid var(--lightBrown);
    box-shadow: 0px 0px 15px var(--lightBrown);
    /* margin: auto; */
    /* margin: auto; */
}
.donation-all {
    width: 70%;
}
.donation-other {
    /* padding-top: 4rem;x   */
}
.donation-other {
    width: 30%;
}
.progress-bars,
.donation {
    /* width: 50%; */
    width: 75%;
    margin: auto;
}
.progress-bars h3 {
    text-align: center;
    font-family: Brush Script MT, Brush Script Std, cursive;

    /* text-decoration: underline; */
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
    font-size: 3.5rem;
}
.progress-bars-child {
    margin-bottom: 3rem;
}

.inner-donation-other {
    width: 90%;
    margin: auto;
}
.org-name {
    display: flex;
    flex-wrap: wrap;
}
.donate-btn {
    border: none;
    border-radius: 0.5rem;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    /* margin-inline: 0.5rem; */
}

.donation-btns label {
    background: var(--darkBlue);
    color: var(--lightBrown);
    padding: 0.75rem;
    padding-block: 0.5rem;
    margin: 0;
    /* border-radius: 0.5rem; */
    width: 45%;
    border-collapse: collapse;
    border: 2px solid var(--lightBrown);
    text-align: center;
}
#bg-highlight {
    border: 2px solid red;
    background-color: red;

    color: var(--darkBlue);
}
.donation-btns input[type='radio'] {
    display: none;
}
.donation-btns {
    display: flex;
    /* gap: 1rem; */
    flex-wrap: wrap;
    width: 75%;
    justify-content: center;
    margin: auto;
}
.cursive {
    font-family: Brush Script MT, Brush Script Std, cursive;
}
.other-donate-title {
    text-align: center;
    color: var(--lightBrown);
}
.donate-title {
    font-size: 4vw;
}
.donation {
    width: 50%;
    /* background-color: #fff; */
}

@media only screen and (min-width: 1920px) {
    .donate-title {
        font-size: 5rem;
    }
    .progress-bars h3 {
        font-size: 3.5rem;
    }
}
@media only screen and (max-width: 910px) {
    .donation-btns {
        width: 100%;
    }
    .donate-btns label {
        border: 1px solid rgb(155, 155, 155);
    }
    #bg-highlight {
        border: 1px solid red;
    }
    .donation {
        width: 75%;
    }
    .donation-with-other {
        flex-direction: column;
    }
    .progress-bars h3 {
        font-size: 3rem;
    }
    .left-wrapper {
        width: 100%;
    }
    .right-wrapper {
        display: none;
    }
    .donation-other {
        width: 100%;
        padding-bottom: 3rem;
        padding-top: 0;
    }
    .progress-bars-child:last-child {
        margin-bottom: 0;
        padding-bottom: 3.5rem;
    }
    .donation-all {
        width: 100%;
    }
    .donate-title {
        font-size: 10vw;
    }
}
.donation-other {
    background-color: var(--darkBlue);
}
.donation-all {
    background-color: var(--lightBrown);
    color: var(--darkBlue);
    /* width: 70%; */
}

.donation-names {
    /* background: #000; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
@keyframes loadingImg {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
.loadingImg div {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 20px solid #000023;
    border-top-color: transparent;
    border-radius: 50%;
}
.loadingImg div {
    animation: loadingImg 5.5555555555555545s linear infinite;
    top: 100px;
    left: 100px;
}
.loadingWrapper {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #e8d0bc;
}
.loadingImg {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
}
.loadingImg div {
    box-sizing: content-box;
}
